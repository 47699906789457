import React from 'react';
import Seo from "../../components/seo"
import AbbPageHtml from '../../components/OtherPages/PartnersPages/abbpage';

function AbbPage() {
    return (
        <>
            <Seo 
                title="Партнер Рефинжиниринг ABB"
                description="Официальный партнер компании 'Рефинжиниринг'-ABB"
                keywords="ABB, электроника, холодильная, партнер"
            />
            <AbbPageHtml />
        </>
    );
}

export default AbbPage;