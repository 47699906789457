import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import TopDark from '../../Modules/Top/TopDarkRelative';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import Footer from '../../Modules/FooterPage';

// LOGO IMAGE 
import AbbSert from './Serts/abb_sert.jpg'

const AbbPageHtml = () => {
    return(
        <>
            <Container fluid>
                <Row>
                    <Col>
                        <TopDark />
                    </Col>
                </Row>
            </Container>
            <div className="refeng-page-container" style={{display: 'grid', alignContent: 'center'}}>
                <div className="postcontent">
                    <Container fluid>
                        <Row>
                            <Col xl={8}>
                                <h4>Компания ABB</h4>
                                <br/>
                                <p>ABB (ABBN: SIX Swiss Ex) – ведущая международная технологическая компания, 
                                    которая способствует трансформации общества, промышленности и инфраструктуры 
                                    на пути к более продуктивному и устойчивому будущему. Объединяя программные 
                                    решения с портфелем продуктов в сфере электрооборудования, робототехники, 
                                    автоматизации и электроприводов, ABB расширяет границы технологий и выводит 
                                    их эффективность на новый уровень. Опираясь на 130‑летний опыт, компания ABB 
                                    добивается успеха благодаря 105 000 высококвалифицированных сотрудников в более 
                                    чем 100 странах.</p>
                                <p>
                                    <strong>Сайт компании:</strong>
                                    <a href="https://global.abb/group/en" rel="noopener noreferrer nofollow" target = "_blank"  aria-label="ABB website">
                                    &nbsp;&nbsp;global.abb/group/en
                                    </a>
                                </p>
                            </Col>
                            <Col></Col>
                            <Col xl={2}>
                                <Zoom>
                                    <img
                                    alt="Сертификат о партнерстве компании Рефинжиниринг с ABB"
                                    src={AbbSert}
                                    width="100%"
                                    />
                                </Zoom>
                            </Col>
                            <Col></Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default AbbPageHtml